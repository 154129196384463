import M$plugin$Attributions from './facade/js/attributions';
import M$control$AttributionsControl from './facade/js/attributionscontrol';
import M$impl$control$AttributionsControl from './impl/ol/js/attributionscontrol';

if (!window.M.plugin) window.M.plugin = {};
if (!window.M.control) window.M.control = {};
if (!window.M.impl) window.M.impl = {};
if (!window.M.impl.control) window.M.impl.control = {};
window.M.plugin.Attributions = M$plugin$Attributions;
window.M.control.AttributionsControl = M$control$AttributionsControl;
window.M.impl.control.AttributionsControl = M$impl$control$AttributionsControl;
